import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ title }) => (
  <div className={styles['breadcrumbs']}>
    <AniLink
      cover
      bg="white"
      direction="left"
      duration={0.5}
      className={styles['breadcrumbs__links']}
      to="/">
      Home
    </AniLink>{' '}
    | <span>{title}</span>
  </div>
);

export default Breadcrumbs;
