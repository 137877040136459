// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { useSiteMetadata } from '../hooks';
import type { RecipeMarkdownRemark } from '../types';
import Recipe from '../components/Recipe';
import getStructuredRecipe from '../utils/get-structured-recipe';

type Props = {
  data: {
    markdownRemark: RecipeMarkdownRemark,
  },
};

const RecipeTemplate = ({ data }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const { frontmatter } = data.markdownRemark;
  const {
    title: postTitle,
    description: postDescription,
  } = frontmatter;
  const metaDescription = postDescription !== null ? postDescription : siteSubtitle;

  return (
    <Layout
      title={`${postTitle} - ${siteTitle}`}
      description={metaDescription}
      socialImage={frontmatter.photo}
      jsonld={getStructuredRecipe({
        name: [frontmatter.titlePrefix, frontmatter.title].filter(Boolean).join(' '),
        author: 'Uma Venkat',
        description: frontmatter.description,
        image: frontmatter.photo,
        prepTime: frontmatter.about.prepTime,
        cookTime: frontmatter.about.cookTime,
        recipeIngredient: frontmatter.ingredients,
        recipeYield: frontmatter.about.servings,
        totalTime: frontmatter.about.totalTime,
        datePublished: frontmatter.date,
        topIngredients: frontmatter.topIngredients,
        recipeCuisine: frontmatter.about.cuisine,
        recipeCategory: frontmatter.categories.join(', '),
        recipeInstructions: frontmatter.photoInstructions[0].steps,
      })}>
      <Recipe recipe={data.markdownRemark} />
    </Layout>
  );
};

export const query = graphql`
  query RecipeBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        tagSlugs
      }
      frontmatter {
        title
        subtitle
        titlePrefix
        description
        socialImage
        topIngredients
        date
        photo
        categories
        about {
          servings
          prepTime
          cookTime
          prepTime
          cuisine
          course
        }
        ingredients {
          title
          items
        }
        instructions {
          title
          steps
        }
        photoInstructions {
          title
          steps {
            text
            photo
          }
        }
      }
    }
  }
`;

export default RecipeTemplate;
