import React from 'react';
import Section from '../Section';
import styles from '../Section/Section.module.scss';

const Instructions = ({ instructions }) => (
  <Section title="Instructions">
    {instructions.map(({ title, steps }, iIndex) => (
      <React.Fragment key={iIndex}>
        <h6 className={styles['section__subtitle']}>{title}</h6>
        <ul>
          {steps.map((step, sIndex) => (
            <li key={sIndex}>{step}</li>
          ))}
        </ul>
      </React.Fragment>
    ))}
  </Section>
);

export default Instructions;
