import React from 'react';
import Section from '../Section';
import styles from '../Section/Section.module.scss';

const Ingredients = ({ ingredients, servings }) => (
  <Section style={{ flex: '1 1 320px' }} title="Ingredients" titleSuffix={`Servings: ${servings}`}>
    {ingredients.map(({ title, items }, index) => (
      <React.Fragment key={index}>
        <h6 className={styles['section__subtitle']}>{title}</h6>
        <ul>
          {items.map((step, idx) => (
            <li key={idx}>{step}</li>
          ))}
        </ul>
      </React.Fragment>
    ))}
  </Section>
);

export default Ingredients;
