import React from 'react';
import Section from '../Section';
import styles from '../Section/Section.module.scss';

const PhotoInstruction = ({ text, photo }) => (
  <li>
    <p>{text}</p>
    {photo && <Section className={styles['section__photo']} style={{ backgroundImage: `url(${photo})` }} />}
  </li>
);

const PhotoInstructions = ({ instructions }) => (
  <Section style={{ width: '100%', contentVisibility: 'auto' }} title="Step by Step Instructions">
    {instructions.map(({ title, steps }, iIndex) => (
      <React.Fragment key={iIndex}>
        <h6 className={styles['section__subtitle']}>{title}</h6>
        <ol>
          {steps.map(({ text, photo }, sIndex) => (
            <PhotoInstruction key={sIndex} text={text} photo={photo} />
          ))}
        </ol>
      </React.Fragment>
    ))}
  </Section>
);

export default PhotoInstructions;
