import React from 'react';
import styles from './Title.module.scss';

const Title = ({ cover, title, subtitle }) => (
  <div className={styles['recipe-title']}>
    <div
      className={styles['recipe-title__cover']}
      style={{
        backgroundImage: `url(${cover})`,
      }}
    />
    <h1 className={styles['recipe-title__title']}>{title}</h1>
    <div className={styles['recipe-title__subtitle']}>{subtitle}</div>
  </div>
);

export default Title;
