import React from 'react';
import Section from '../Section';
import styles from '../Section/Section.module.scss';
import getDuration from '../../../utils/get-duration';

// eslint-disable-next-line no-unused-vars
const PrintIcon = () => (
  <svg fill="currentColor" width="17.778" height={16} viewBox="0 0 17.778 16">
    <path
      d="M18.111,8.944H5.667A2.663,2.663,0,0,0,3,11.611v5.333H6.556V20.5H17.222V16.944h3.556V11.611A2.663,2.663,0,0,0,18.111,8.944Zm-2.667,9.778H8.333V14.278h7.111ZM18.111,12.5A.889.889,0,1,1,19,11.611.892.892,0,0,1,18.111,12.5Zm-.889-8H6.556V8.056H17.222Z"
      transform="translate(-3 -4.5)"
    />
  </svg>
);

const About = ({
  description, prepTime, cookTime, cuisine, course
}) => (
  <Section style={{ flex: '1 1 320px' }} title="About">
    <div className={styles['section__content']}>{description}</div>
    <div className={styles['section__content']}>
      <p>
        <span className={styles['section__label']}>Preparation Time:</span>
        {getDuration(prepTime)}
      </p>
      <p>
        <span className={styles['section__label']}>Cook Time:</span>
        {getDuration(cookTime)}
      </p>
      <p>
        <span className={styles['section__label']}>Cuisine:</span>
        {cuisine}
      </p>
      <p>
        <span className={styles['section__label']}>Course:</span>
        {course}
      </p>
    </div>
    <footer>
      {/* <button className={styles['section__button']}>
        <span>
          <PrintIcon />
        </span>
        Print Recipe
      </button> */}
    </footer>
  </Section>
);

export default About;
