import React from 'react';
import clsx from 'clsx';
import styles from './Section.module.scss';

const Section = ({
  title,
  titleSuffix = '',
  children,
  className,
  ...others
}) => (
  <div className={clsx(styles['section'], className)} {...others}>
    <h4 className={styles['section__title']}>
      {title}
      {titleSuffix && (
        <span className={styles['section__title__suffix']}>{titleSuffix}</span>
      )}
    </h4>
    {children}
  </div>
);

export default Section;
