export default function recipe({
  topIngredients,
  name,
  image,
  datePublished,
  description,
  prepTime,
  cookTime,
  totalTime,
  recipeYield,
  recipeIngredient,
  recipeInstructions,
  recipeCuisine,
  recipeCategory,
}) {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Recipe',
    name,
    author: {
      '@type': 'Person',
      name: 'Uma Venkat',
    },
    datePublished,
    image: [image, image, image],
    description,
    recipeCuisine,
    keywords: topIngredients,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: 0,
      reviewCount: 0,
      bestRating: '5',
      worstRating: '1',
    },
    prepTime,
    cookTime,
    totalTime,
    recipeYield,
    recipeCategory,
    recipeIngredient: recipeIngredient[0].items,
    recipeInstructions: recipeInstructions.map(({ text, photo }) => ({
      '@type': 'HowToStep',
      name: text,
      text,
      image: photo
    }))
  };
}
