// @flow strict

import React from 'react';
import type { RecipeMarkdownRemark } from '../../types';
import styles from './Recipe.module.scss';
import About from './About';
import Instructions from './Instructions';
import Ingredients from './Ingredients';
import Breadcrumbs from './Breadcrumbs';
import PhotoInstructions from './PhotoInstructions';
import Title from './Title';
import Topbar from '../Topbar';
import Social from '../Social';
import Footer from '../Footer';

const getId = (s) => s.replace(/\//g, '-');

const Recipe = ({
  recipe: { frontmatter, fields },
}: {
  recipe: RecipeMarkdownRemark,
}) => (
  <>
    <div className={styles['recipe']}>
      <div className={styles['recipe__header']}>
        <Topbar isDetailPage />
        <Social />
        <div className={styles['recipe__content']}>
          <Breadcrumbs title={frontmatter.title} />
          <Title
            cover={frontmatter.photo}
            titlePrefix={frontmatter.titlePrefix}
            title={frontmatter.title}
            subtitle={frontmatter.subtitle}
          />
        </div>
      </div>
      <div className={styles['recipe__content']}>
        <div className={styles['recipe__about-ingredients']}>
          <Ingredients
            servings={frontmatter.about.servings}
            ingredients={frontmatter.ingredients}
          />
          <About
            description={frontmatter.description}
            prepTime={frontmatter.about.prepTime}
            cookTime={frontmatter.about.cookTime}
            course={frontmatter.about.course}
            cuisine={frontmatter.about.cuisine}
          />
          <Instructions instructions={frontmatter.instructions} />
          <PhotoInstructions instructions={frontmatter.photoInstructions} />

          <div
            className="yotpo yotpo-main-widget"
            data-product-id={getId(fields.slug)}
            data-name={frontmatter.title}
            data-url={fields.slug}
            data-image-url={frontmatter.photo}></div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);

export default Recipe;
